import { DOMAttributes, FC, PropsWithChildren, ReactNode, createElement, useEffect, useState, useCallback } from "react"
import { cx } from "./cx"
import { ChaptersList } from "./ChaptersList"
import { NextChapterLink } from "./NextChapterLink"

function isObject(obj: any) {
    return typeof obj === 'object' && !Array.isArray(obj) && obj !== null
}

export function getParam(name: "book" | "chapter") {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get(name)
}

export function getChapter() {
    return getParam("chapter")
}

export function Chapter1() {
    const book = getParam("book")
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<[string, any][]>([])
    const file = getChapter()

    useEffect(function () {
        (async function () {
            if (file && file !== "/") {
                setLoading(true)
                const a = await (await fetch(`static/${book}/${file}.json`)).json()
                setLoading(false)
                setData(Object.entries(a))
            }
        })()
    }, [book, setData, file, setLoading])

    if (loading) {
        return <>...</>
    }

    return (
        <>
            {data.length === 0 && !loading && (
                <div>
                    <img
                        className="md:max-w-[50%]"
                        src={`static/img/${book}/000-001.jpg`}
                        alt=""
                    />
                    <ChaptersList showSubtitle />
                </div>
            )}
            {data.map(function ([tag, content], index) {
                return Array.isArray(content) ? content.map(function (contentString, j) {
                    if (isObject(contentString)) {
                        return Object.entries(contentString).map(function ([tag, value]: [string, any], i) {
                            if (tag === "img") {
                                let src = value
                                let className = "md:float-right md:ml-3"

                                if (isObject(value)) {
                                    src = value.src
                                    if (value.placement === "left") {
                                        className = "md:float-left md:mr-3"
                                    }
                                }

                                return (
                                    <img
                                        className={cx("md:max-w-[250px] lg:max-w-[350px] 2xl:max-w-[500px]", className)}
                                        src={`static/img/${book}/${file}-${src}`}
                                        alt={value as string}
                                        key={index + j + i}
                                    />
                                )
                            }

                            if (tag === "table") {
                                return (
                                    <table key={index + j + i}>
                                        <tbody>
                                        {Object.entries(value).map(function ([tag2, trs], index2) {
                                            return (trs as any[]).map(function (val, index3) {
                                                return Object.entries(val).map(function ([tag, content], i) {
                                                    return (
                                                        <CreateTag name={tag2} key={index2} storeKey={`${index2}`}>
                                                            {(content as any[]).map(function (contentString, j) {
                                                                return (
                                                                    <CreateTag
                                                                        name={tag}
                                                                        key={`${index3}${j}`}
                                                                        storeKey={`${index3}${j}`}
                                                                        html={contentString}
                                                                    />
                                                                )
                                                            })}
                                                        </CreateTag>
                                                    )
                                                })
                                            })
                                        })}
                                        </tbody>
                                    </table>
                                )
                            }

                            if (isObject(value) && value.class && value.text) {
                                return (
                                    <CreateTag
                                        name={tag}
                                        key={`${index}-${j}-${i}`}
                                        storeKey={`${index}-${j}-${i}`}
                                        className={value.class || ""}
                                        html={value.text}
                                    />
                                )
                            }

                            return (
                                <CreateTag
                                    name={tag}
                                    key={`${index}-${j}-${i}`}
                                    storeKey={`${index}-${j}-${i}`}
                                    html={value}
                                />
                            )
                        })
                    }

                    return (
                        <CreateTag
                            name={tag}
                            key={`${index}-${j}`}
                            storeKey={`${index}-${j}`}
                            html={contentString}
                        />
                    )
                }) : (
                    <CreateTag
                        name={tag}
                        key={`${index}`}
                        storeKey={`${index}`}
                        html={content}
                    />
                )
            })}
            {data.length > 0 && !loading && (
                <NextChapterLink />
            )}
        </>
    )
}

type CreateTagProps = PropsWithChildren<{
    name: string
    className?: string
    html?: ReactNode
    storeKey?: string
}>

const STORAGE_KEY = "chapters-read"

function CreateTag({ name, storeKey, className, children, html }: CreateTagProps) {
    const [classes, setClasses] = useState("")
    const key = [getChapter(), storeKey].join("-")

    useEffect(function () {
        if (storeKey) {
            try {
                const storage = localStorage.getItem(STORAGE_KEY)
                if (storage !== null) {
                    const store = JSON.parse(storage)
                    if (store[key]) {
                        setClasses([className, "checked"].join(" "))
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [storeKey, key, setClasses, className])

    const onClick = useCallback(function () {
        if (storeKey) {
            try {
                const storage = localStorage.getItem(STORAGE_KEY)
                if (storage !== null) {
                    const store = JSON.parse(storage)
                    store[key] = !store[key]
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(store))
                    setClasses([className, store[key] ? "checked" : ""].join(" "))
                } else {
                    localStorage.setItem(STORAGE_KEY, JSON.stringify({ [key]: true }))
                }
            } catch (e) {
                console.log(e)
            }
        }
    }, [key, storeKey, setClasses, className])

    return createElement(name, {
        ...(className || classes ? { className: [className, classes].join(" ") } : {}),
        children,
        ...(html ? { dangerouslySetInnerHTML: { __html: html } } : {}),
        ...(name === "h4" && storeKey !== undefined ? { onClick } : {}),
    })
}
