export function Footer() {
    return (
        <footer className="h-40 md:h-80 bg-[--alt-background-color]">
            <div className="mx-auto max-w-screen-2xl">
                <div className="text-center py-3">
                    &copy; bonez
                </div>
            </div>
        </footer>
    )
}
